import {Button} from '@mui/material'
import {Home} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'
import React from 'react'
import {PageLayout} from '../utils/PageLayout'

export const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <PageLayout>
      <h1>Page not found</h1>
      <Button variant="contained" startIcon={<Home/>} onClick={() => navigate('/')}>Zur Willkommen Seite</Button>
    </PageLayout>
  )
}