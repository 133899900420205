import React from 'react'
import {Container, Typography} from '@mui/material'
import {useMedia} from '../../utils/useMedia'

export const WelcomeView = () => {
  const media = useMedia()
  return (
    <>
      <img
        src="praying.jpg"
        alt="praying"
        width="100%"
        style={{
          marginTop: media.isSuperSmall || media.isSmall ? -40 : 0,
          maxHeight: 375,
          overflow: 'hidden',
          objectFit: 'cover',
          borderRadius: media.isSuperSmall || media.isSmall ? 0 : 6,
        }}/>
      <Container sx={{textAlign: 'justify'}}>
        <Typography variant="h3" fontWeight="bold" sx={{mt: 3}}>Grüß Gott</Typography>
        <Typography fontSize={20} sx={{mt: 3}}>
          Gebet ist die Kommunikation mit Gott.
          Ob im Lobpreis, im Dank- oder Fürbittgebet, wir suchen seine
          heilsame Nähe. Gebete dringen tief in unser Inneres ein.
        </Typography>
        <Typography fontSize={20} sx={{mt: 2}}>
          Zu allen Lebenslagen und für jede Tageszeit gibt es Gebete.
          Pray easy stellt eine kleine Auswahl inniger Gebete bereit.
        </Typography>
        <Typography fontSize={20} sx={{mt: 2}}>
          Es sind „einfache“ Gebete. Gott freut sich über jedes Gebet
          und in seiner Barmherzigkeit kommt er dem Betenden oder auch
          demjenigen, für den gebetet wird, mit Wohlwollen entgegen.
        </Typography>
      </Container>
    </>
  )
}