import React, {ReactElement, ReactNode, useState} from 'react'
import {useMedia} from './useMedia'
import {BottomNavigation, BottomNavigationAction, Box, Container, Grid, Paper, Tab, Tabs} from '@mui/material'
import {Headline} from './Headline'
import {useSearchParams} from 'react-router-dom'

export type TabsContainerProps = {
  tabs: TabItem[]
}

export const TabsContainer = ({tabs}: TabsContainerProps) => {
  const media = useMedia()

  if (media.isSmall) {
    return <MobileTabsContainer tabs={tabs}/>
  }
  return <DesktopTabsContainer tabs={tabs}/>
}

const DesktopTabsContainer = ({tabs}: TabsContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const index = tabs.findIndex(it => it.key === searchParams.get('tab'))
  const [tab, setTab] = useState<number>(
    index === -1 ? 0 : index,
  )

  return (
    <Grid container width="100%" spacing={3} sx={{mt: 1}}>
      <Grid item xs={3} maxWidth={200}>
        <Tabs
          orientation="vertical"
          value={tab}
          onChange={(_, i) => {
            setTab(i)
            setSearchParams({tab: tabs.at(i)?.key || tabs[0].key})
          }}
        >
          {tabs.map(t => (
            <Tab
              key={t.key}
              icon={t.icon}
              iconPosition="end"
              label={t.label}
              sx={{fontWeight: 'bold', justifyContent: 'end'}}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={9} sx={{mb: 3}}>
        <Container style={{maxWidth: 1200}}>
          {tabs.at(tab)?.showTitle && <Headline text={tabs.at(tab)?.label}/>}
          {tabs[tab].render()}
        </Container>
      </Grid>
    </Grid>
  )
}

const MobileTabsContainer = ({tabs}: TabsContainerProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const index = tabs.findIndex(it => it.key === searchParams.get('tab'))
  const [tab, setTab] = useState<number>(
    index === -1 ? 0 : index,
  )

  return (
    <>
      <Box style={{maxWidth: 850, marginBottom: 65, marginTop: 30}}>
        {tabs.at(tab)?.showTitle && <Headline text={tabs.at(tab)?.label}/>}
        <Box sx={{mt: 1}}>
          {tabs.at(tab)?.render()}
        </Box>
      </Box>
      <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
        <BottomNavigation
          value={tab}
          onChange={(_, i) => {
            setTab(i)
            setSearchParams({tab: tabs.at(i)?.key || tabs[0].key})
          }}
        >
          {tabs.map(t => (
            <BottomNavigationAction
              key={t.key}
              icon={t.icon}
              label={t.label}
              showLabel={true}
              sx={{textTransform: 'uppercase'}}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </>
  )
}

export type TabItem = {
  label: string
  key: string
  icon: ReactElement
  showTitle?: boolean
  render: () => ReactNode
}