import {ExpandLess, ExpandMore, Pause, PlayArrow} from '@mui/icons-material'
import {Box, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Typography} from '@mui/material'
import React, {useCallback, useRef, useState} from 'react'
import {useMedia} from './useMedia'

export interface PictureProps {
  picturePortrait?: string
  pictureLandscape?: string
}

export interface PrayerCardProps {
  title: string
  lines?: string[]
  picture?: PictureProps
  audio?: string
  parts?: {
    title: string
    lines?: string[]
  }[]
  caption?: string
}

export const PrayerCard = ({title, lines, parts, picture, audio, caption}: PrayerCardProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false)
  const audioRef = useRef<HTMLAudioElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePlay = useCallback((e?: any) => {
    e?.stopPropagation && e.stopPropagation()
    e?.preventDefault && e.preventDefault()
    if (audioPlaying) {
      audioRef?.current?.pause()
    } else {
      audioRef?.current?.play()
    }
    setAudioPlaying(!audioPlaying)
  }, [audioPlaying, setAudioPlaying, audioRef])

  return (
    <Card sx={{mb: 2, ml: 1, mr: 1}}>
      <CardHeader
        title={<Typography fontWeight="bold">{title}</Typography>}
        onClick={() => setOpen(!open)}
        action={
          <>
            {audio && (
              <>
                <IconButton onClick={handlePlay}>
                  {!audioPlaying && <PlayArrow/>}
                  {audioPlaying && <Pause/>}
                </IconButton>
                <audio ref={audioRef} onEnded={() => handlePlay()} src={audio}/>
              </>
            )}
            <IconButton>
              {open ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
          </>
        }
      />
      <Collapse in={open} unmountOnExit>
        <CardContent sx={{textAlign: 'center'}}>
          <PrayerItem lines={lines} parts={parts} picture={picture} caption={caption}/>
        </CardContent>
      </Collapse>
    </Card>
  )
}

const PrayerPart = ({title, lines, index}: {
  title: string,
  lines?: string[],
  index: number,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Card sx={{mt: index > 0 ? 1 : 0}}>
      <CardHeader
        onClick={() => setOpen(!open)}
        title={<Typography fontWeight="bold">{title}</Typography>}
        action={<IconButton>{open ? <ExpandLess/> : <ExpandMore/>}</IconButton>}
      />
      <Collapse in={open} unmountOnExit>
        <CardContent sx={{textAlign: 'center'}}>
          <>
            {lines?.map((l, i) => (
              <Typography key={i}>{l.length === 0 ? <span>&nbsp;</span> : l}</Typography>
            ))}
          </>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export interface PrayerItemProps {
  lines?: string[]
  parts?: {
    title: string
    lines?: string[]
  }[]
  picture?: PictureProps
  caption?: string
}

export const PrayerItem = ({parts, lines, picture, caption}: PrayerItemProps) => {
  const media = useMedia()

  if (media.isSuperSmall || media.isSmall) {
    return (
      <>
        {picture && picture.pictureLandscape && (
          <img src={picture?.pictureLandscape} width="100%" style={{marginBottom: 15, borderRadius: 6}}/>
        )}
        {lines?.map((l, i) => (
          <Typography key={i}>{l.length === 0 ? <span>&nbsp;</span> : l}</Typography>
        ))}
        <Box textAlign="right" sx={{mt: 2}}>
          {caption && (
            <Typography fontSize={12} fontStyle="italic">{caption}</Typography>
          )}
        </Box>
        {parts?.map((p, i) => (
          <PrayerPart key={i} title={p.title} lines={p.lines} index={i}/>
        ))}
        {picture && picture.picturePortrait && !picture.pictureLandscape && (
          <img src={picture?.picturePortrait} width="100%" style={{marginTop: 15, borderRadius: 6}}/>
        )}
      </>
    )
  }
  return (
    <>
      <Grid container>
        {picture && picture.picturePortrait && (
          <Grid item xs={3} sx={{justifyItems: 'center', alignItems: 'center'}}>
            <img src={picture?.picturePortrait} style={{maxWidth: '90%', borderRadius: 6}}/>
          </Grid>
        )}
        <Grid item xs={picture && picture.picturePortrait ? 9 : 12}>
          {lines?.map((l, i) => (
            <Typography key={i}>{l.length === 0 ? <span>&nbsp;</span> : l}</Typography>
          ))}
          <Box textAlign="right" sx={{mt: 2, fontStyle: 'italic'}}>
            {caption && <Typography fontSize={12} textAlign="right">{caption}</Typography>}
          </Box>
          {parts?.map((p, i) => (
            <PrayerPart key={i} title={p.title} lines={p.lines} index={i}/>
          ))}
        </Grid>
      </Grid>
    </>
  )
}