import React, {PropsWithChildren} from 'react'
import ScrollToTop from '../utils/ScrollToTop'
import {AppBar, Button, Toolbar, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'

export const PageLayout = ({children}: PropsWithChildren) => {
  const navigate = useNavigate()

  return (
    <>
      <ScrollToTop/>
      <AppBar position="relative" color="primary">
        <Toolbar>
          <Button
            variant="text"
            startIcon={<img src="/logo.png" height={38} style={{marginBottom: 3}} alt="logo"/>}
            style={{marginLeft: -8}}
            onClick={() => navigate('/')}
          >
            <Typography
              color="black"
              variant="h4"
              fontWeight="bold"
              sx={{textTransform: 'none', ml: 2}}
            >
              Pray easy
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
      <div id="PageLayoutContainerDiv" style={{marginTop: 12}}>
        {children}
      </div>
    </>
  )
}