import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {ThemeProvider} from './components/ThemeProvider'
import {BrowserRouter} from 'react-router-dom'
import {Router} from './components/Router'
import {MessageSnackbar} from './components/utils/MessageSnackbar'

// eslint-disable-next-line no-console
console.log(
  '############################\n' +
  '# _ __  _ __ __ _ _   _        ___  __ _ ___ _   _ \n' +
  '#| \'_ \\| \'__/ _` | | | |_____ / _ \\/ _` / __| | | |\n' +
  '#| |_) | | | (_| | |_| |_____|  __/ (_| \\__ \\ |_| |\n' +
  '#| .__/|_|  \\__,_|\\__, |      \\___|\\__,_|___/\\__, |\n' +
  '#|_|              |___/                      |___/ \n' +
  '#\n' +
  `# version: ${process.env.REACT_APP_VERSION}\n` +
  `# time: ${process.env.REACT_APP_DEPLOYMENT_TIME}\n` +
  '############################\n',
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <Router/>
        <MessageSnackbar/>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
)
