import React, {PropsWithChildren} from 'react'
import {createTheme, ThemeProvider} from '@mui/material'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/300-italic.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/400-italic.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/500-italic.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/700-italic.css'


const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
    allVariants: {
      userSelect: 'none',
    },
  },
  palette: {
    primary: {
      main: '#99c0ee',
    },
    secondary: {
      main: '#8C2020',
    },
    text: {
      primary: '#010101',
    },
    background: {
      default: '#F1F1F1',
      paper: '#F1F1F1',
    },
  },
})

const CustomThemeProvider = ({children}: PropsWithChildren) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export {CustomThemeProvider as ThemeProvider}