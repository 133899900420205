import {PageLayout} from '../utils/PageLayout'
import React from 'react'
import {TabsContainer} from '../utils/TabsContainer'
import {Article, Info, WavingHand} from '@mui/icons-material'
import {PrayerView} from './Views/PrayerView'
import {WelcomeView} from './Views/WelcomeView'
import {ImpressView} from './Views/ImpressView'

export const LandingPage = () => {
  return (
    <PageLayout>
      <TabsContainer tabs={[
        {
          label: 'Willkommen',
          key: 'welcome',
          icon: <WavingHand/>,
          render: () => <WelcomeView/>,
        }, {
          label: 'Gebete',
          key: 'prayers',
          icon: <Article/>,
          showTitle: true,
          render: () => <PrayerView/>,
        }, {
          label: 'Impressum',
          key: 'impress',
          icon: <Info/>,
          showTitle: true,
          render: () => <ImpressView/>,
        },
      ]}/>
    </PageLayout>
  )
}