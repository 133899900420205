import React from 'react'
import {PrayerCard, PrayerCardProps} from '../../utils/PrayerCard'

const prayers: PrayerCardProps[] = [
  {
    title: 'Gebet zum Heiligen Geist',
    audio: 'heilig-geist-gebet.mp3',
    picture: {
      pictureLandscape: 'heiliger-geist-landscape.jpeg',
      picturePortrait: 'heiliger-geist-portrait.jpeg',
    },
    lines: [
      'Oh Heiliger Geist,',
      'Du Liebe des Vaters und des Sohnes.',
      'Gib mir immer ein, was ich denken soll.',
      'Gib mir immer ein, was ich sagen soll und wie ich es sagen soll.',
      'Gib mir ein, was ich verschweigen soll; und wie ich mich dabei verhalten soll.',
      'Gib mir ein, was ich zur Ehre Gottes, zum Wohl der Seelen',
      'und zu meiner eigenen Heiligung tun soll.',
      '',
      'Heiliger Geist,',
      'Gib mir Verstand, um zu verstehen und zu erkennen.',
      'Gib mir das Fassungsvermögen, um alles zu behalten.',
      'Lehre mich die Methoden und gib mir die Fähigkeit, um immer wieder zu lernen.',
      'Gib mir Scharfsinn, um richtig zu deuten und zu unterscheiden.',
      'Gib mir die Gnade, um wirkungsvoll zu sprechen.',
      '',
      'Heiliger Geist,',
      'Gib mir Zuversicht und Treffsicherheit am Beginn;',
      'leite und führe mich bei der Ausführung',
      'und schenke mir Vollkommenheit beim Beenden.',
      '',
      'Amen.',
    ],
    caption: 'Tägliches Gebet des Heiligen Papst Johannes Paul II.',
  },
  {
    title: 'Gebet zum Heiligsten Herzen Jesu',
    parts: [
      {
        title: 'Morgengebet',
        lines: [
          'Wenn ich erwach` am frühen Morgen,',
          'Herz Jesu, dann begrüß` ich dich,',
          'um für des Tages Müh` und Sorgen',
          'dir innig zu empfehlen mich.',
          '',
          'Auf dich, o Herz, will ich nur schauen,',
          'du bist mein Stern, mein Trost, mein Licht!',
          'Und mutig will ich dir vertrauen,',
          'denn deine Treue wanket nicht.',
          '',
          'So nimm mein Herz dir ganz zu eigen,',
          'gib du ihm Kraft zur guten Tat.',
          'Und sollte es zur Sünde neigen,',
          'halt du es fest in deiner Gnad.',
          '',
          'Von dir soll heute nichts mich scheiden.',
          'Verbirg in deine Wunden mich,',
          'dort will ich beten, opfern, leiden',
          'und sterben, wenn du willst, für dich.',
          '',
          'In deine Liebe ich empfehle',
          'auch alle, die mir nahe stehn;',
          'behüte sie an Leib und Seele,',
          'lass keinen heute irre gehn!',
          '',
          'Und nun gib mir den Morgensegen,',
          'er weihe diesen Tag mir ein,',
          'und sei mit mir auf allen Wegen,',
          'bis ich werd` ewig bei dir sein!',
          '',
          'Amen.',
        ],
      },
      {
        title: 'Abendgebet',
        lines: [
          'Den letzten Gruß der Abendstunde',
          'send` ich zu dir, o göttlich Herz!',
          'In deine heil`ge Liebeswunde,',
          'senk` ich des Tages Freud` und Schmerz.',
          '',
          'O Herz der Liebe, Dir vertraute',
          'am Morgen ich des Tages Last,',
          'und nicht umsonst ich auf dich baute,',
          'voll Huld du mich gesegnet hast.',
          '',
          'O habe Dank für deine Güte,',
          'die schützend mir zur Seite stand,',
          'auch diese Nacht mich treu behüte',
          'durch deines heil`gen Engels Hand.',
          '',
          'O göttlich Herz, all meine Sünden',
          'bereue ich aus Lieb` zu dir;',
          'o lasse mich Verzeihung finden;',
          'schenk deine Lieb aufs neue mir!',
          '',
          'Herz Jesu, innig ich empfehle',
          'auch alle meine Lieben dir,',
          'beschütze sie an Leib und Seele,',
          'die Gutes je erwiesen mir.',
          '',
          'In deiner heil`gen Herzenswunde',
          'schlaf ich nun sanft und ruhig ein,',
          'o lass sie in der letzten Stunde,',
          'mir eine Himmelspforte sein!',
          '',
          'Amen.',
        ],
      },
    ],
  },
  {
    title: 'Gebet zu Gott Vater',
    picture: {
      picturePortrait: 'gott-vater-portrait.jpeg',
    },
    lines: [
      'GOTT IST MEIN VATER',
      '',
      'Mein Vater im Himmel, wie wohltuend und heilsam ist es, zu wissen: Du bist mein Vater und ich bin Dein Kind.',
      '',
      'Vor allem, wenn es dunkel ist in meiner Seele und mein Kreuz zu schwer wird, dann spüre ich, wie notwendig es für mich ist, Dir immer wieder zu sagen:',
      '„Vater, ich glaube an Deine Liebe zu mir!“',
      '',
      'Ich glaube, dass Du mein Vater bist und dass ich Dein Kind bin!',
      'Ich glaube: Du liebst mich mit unendlicher Liebe!',
      'Ich glaube: Tag und Nacht wachst Du über mir; ohne Deine Zustimmung fällt kein Haar von meinem Haupte!',
      'Ich glaube: Du Allwissender weißt besser als ich, was gut ist für mich!',
      'Ich glaube: Du Allmächtiger kannst auch das Böse zum Guten wenden!',
      'Ich glaube: Du Allgütiger lässt denen, die Dich lieben, alle Dinge zum Besten gereichen: selbst in der Hand, die mich schlägt, spüre ich Deine heilende Hand!',
      'Ich glaube! Doch festige in mir den Glauben, die Hoffnung und die Liebe!',
      'Lehre mich, Deine Liebe zu erkennen, die mich in jedem Augenblick meines Lebens führt.',
      'Lehre mich, mich ganz Dir zu überlassen, wie sich ein kleines Kind den Armen seiner Mutter überlässt.',
      'Vater, Du weißt alles, Du siehst alles, Du kennst mich besser, als ich mich selbst kenne, Du vermagst alles und Du liebst mich!',
      'Mein Vater, weil Du willst, dass wir Dich um alles bitten, komme ich vertrauensvoll zu Dir, um Dich mit Jesus und Maria zu bitten:',
      '(Bitte um eine besondere Gnade)',
      '',
      'Vereint mit ihren Heiligsten Herzen bringe ich Dir in diesem Anliegen alle meine Gebete, Opfer und Verzichte dar und verspreche eine größere Treue in der Erfüllung meiner Aufgaben.',
      'Schenke mir das Licht, die Kraft und die Gnade Deines Heiligen Geistes.',
      'Stärke mich in diesem Geist, so dass ich Ihn niemals verliere, Ihn nicht betrübe und Seine Stimme in mir nicht schwäche.',
      'Mein Vater, darum bitte ich Dich im Namen Deines Sohnes Jesus!',
      'Und Du, Jesus, öffne mir Dein Herz, lege das meine hinein und bringe es zusammen mit dem Herzen Mariens unserem Göttlichen Vater dar.',
      'Erlange mir die Gnade, um die ich gebeten habe.',
      'Göttlicher Vater, rufe alle Menschen zu Dir.',
      'Die ganze Welt möge Deine väterliche Güte und Deine Göttliche Barmherzigkeit verkünden!',
      'Sei mir ein zärtlicher Vater und beschütze mich überall wie den Stern Deines Auges.',
      'Gib, dass ich immer Dein Kind bleibe, das Deiner würdig ist.',
      'Erbarme Dich meiner.',
      'Göttlicher Vater, erquickende Hoffnung unserer Seelen;',
      'alle Menschen mögen Dich erkennen, verehren und lieben!',
      'Göttlicher Vater, unendliche Güte, die sich über alle',
      'Menschen ergießt:',
      'alle Menschen mögen Dich erkennen, verehren und lieben!',
      'Göttlicher Vater, segensreicher Tau der Menschheit:',
      'alle Menschen mögen Dich erkennen, verehren und lieben!',
      '',
      'Amen.',
    ],
    caption: 'Mutter Eugenia Ravasio',
  },
  {
    title: 'Gebet zum Barmherzigen Jesus',
    picture: {
      picturePortrait: 'jesus-portrait.jpeg',
    },
    lines: [
      'Jesus, ich vertraue auf dich.',
      '',
      'Barmherziger Jesus, ich vertraue auf Dich!',
      'Nichts soll mich mehr ängstigen und beunruhigen. Ich vertraue',
      'auf Dich früh und spät, in Freuden und Leiden, in Versuchungen',
      'und Gefahren, im Glück und Unglück, im Leben und Tode, für',
      'Zeit und Ewigkeit. Ich vertraue auf Dich beim Gebete und bei',
      'der Arbeit, bei Erfolgen und Misserfolgen, im Wachen und',
      'Ruhen, bei Trübsal und Traurigkeit, ja selbst in meinen Fehlern',
      'und Sünden will ich unerschütterlich auf Dich vertrauen.',
      'Du bist ja der Ankergrund meiner Hoffnung, der Stern meiner',
      'Wanderschaft, die Stütze meiner Schwachheit, die Verzeihung',
      'meiner Sünden, die Kraft meiner Tugend, die Vollendung',
      'meines Lebens, der Trost meiner Sterbestunde, die Freude',
      'und Wonne meines Himmels.',
      'Barmherziger Jesus, Du starke Ruhe und sichere Burg meiner',
      'Seele, vermehre mein Vertrauen und vollende meinen Glauben',
      'an Deine Macht und Güte. Wenn ich auch der ärmste Deiner',
      'Verehrer und der letzte Deiner Diener bin, so will ich doch',
      'groß und vollkommen sein im Vertrauen, dass Du mein Heil',
      'und meine Rettung bist für die ganze Ewigkeit. Dieses mein',
      'Vertrauen sei meine Empfehlung bei Dir, jetzt und alle Zeit,',
      'am meisten aber in der Stunde meines Todes!',
      '',
      'Amen.',
    ],
    caption: 'Heilige Schwester Faustyna',
  },
  {
    title: 'Gebet zum Heiligen Josef',
    lines: [
      'Heiliger Josef, du halt Haus',
      'und gieß des Himmels Segen aus.',
      'Hier über unserm kleinen Herd,',
      'dass Lieb und Eintracht stets sich mehrt,',
      'dass Fried und Freude uns begleit,',
      'und Gottesfurcht uns steh zur Seit,',
      'dass unser Weg zum Himmel führt,',
      'und unser Tun die Tugend ziert.',
      '',
      'Dies ist heute unsres Herzens Bitt:',
      'o sei und bleib in unsrer Mitt!',
      'Dir geben wir mit frohem Blick',
      'den Schlüssel zu des Hauses Glück.',
      'O schließe du doch alles aus,',
      'was schaden könnte unserm Haus.',
      'Schließ all die Meinen und auch mich',
      'in Jesu Herz, das bitt ich dich!',
      'Dass hier uns jeder Tag vergeht,',
      'wie dir im Haus zu Nazareth!',
      '',
      'Amen.',
    ],
  },
  {
    title: 'Gebet zum Heiligen Bruder Konrad',
    lines: [
      'Wahrer Gottesmann, heiliger Bruder Konrad, du treuer Helfer',
      'in allen Anliegen und Nöten! Du bist voll Güte und Liebe zu',
      'uns Menschen und allen betrübten Herzen bist du ein Tröster',
      'und Helfer. Voll Vertrauen und demütigen Herzen komme ich',
      'darum zu dir und stelle dir mein Anliegen vor. Dein Erdenleben',
      'bestand ja vor allem darin, den Armen in Liebe zu helfen. Da',
      'du jetzt vom Himmel aus noch viel besser helfen kannst, so wirst',
      'du mich mit meinem Anliegen sicher nicht von dir stoßen. Nein,',
      'guter Bruder Konrad, du wirst mich vielmehr gerne erhören und',
      'mir gnädig von deinem geliebten Heiland erflehen, um was ich dich',
      'inständig bitte.',
      '',
      'So hilf mir denn in meiner Not und stehe mir bei in meiner',
      'Armseligkeit! Fest vertraue ich auf dich, du wirst',
      'mir sicher helfen. Sollte es aber in Gottes heiligem Ratschluss',
      'anders beschlossen sein, so geschehe nicht mein, sondern Gottes',
      'gütigster Wille. Heiliger Bruder Konrad, erlange mir dann aber',
      'die Kraft, geduldig zu ertragen, auf dass meine Not ein gott-',
      'gefälliges Kreuztragen sei und ich mir dadurch die ewige',
      'Herrlichkeit verdiene.',
      '',
      'Amen.',
    ],
  },
  {
    title: 'Gebet des Seligen Pater Rupert Mayer',
    lines: [
      'Herr, wie du willst, soll mir gescheh`n,',
      'und wie du willst, so will ich geh`n,',
      'hilf deinen Willen nur versteh`n!',
      '',
      'Herr, wann du willst, dann ist es Zeit,',
      'und wann du willst, bin ich bereit,',
      'heut` und in alle Ewigkeit.',
      '',
      'Herr, was du willst, das nehm` ich hin,',
      'und was du willst, ist mir Gewinn;',
      'genug, dass ich dein Eigen bin.',
      '',
      'Herr, weil du`s willst, d`rum ist es gut.',
      'Und weil du`s willst, d`rum hab ich Mut,',
      'mein Herz in deinen Händen ruht.',
      '',
      'Amen.',
    ],
  },
]

export const PrayerView = () => {
  return (
    <>
      {prayers.map((p, i) => (
        <PrayerCard
          key={i}
          title={p.title}
          lines={p.lines}
          parts={p.parts}
          picture={p.picture}
          audio={p.audio}
          caption={p.caption}/>
      ))}
    </>
  )
}
