import {Route, Routes} from 'react-router-dom'
import React from 'react'
import {LandingPage} from './pages/LandingPage'
import {NotFoundPage} from './pages/NotFoundPage'

export const Router = () => {
  return (
    <Routes>
      <Route key="landing" path="/" index element={<LandingPage/>}/>
      <Route key="notfound" path="*" element={<NotFoundPage/>}/>
    </Routes>
  )
}