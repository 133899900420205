import {Typography} from '@mui/material'
import React from 'react'

export type HeadlineProps = {
  text: string | undefined
}

export const Headline = ({text}: HeadlineProps) => (
  <Typography
    fontSize={24}
    fontWeight="bold"
    sx={{mb: 1.5, ml: 2}}
  >
    {text}
  </Typography>
)